import React, { useState, useEffect } from "react"
import Image from "gatsby-image"
import Carousel from "nuka-carousel"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import clientConfig from "../../../client-config"
import Vibrant from "node-vibrant"

function ImageCarousel({ images }) {
  const [imagesPalette, setImagesPalette] = useState(null)
  useEffect(() => {
    async function getPalletes(images) {
      const pallette = images.map(async (node) => {
        const fluidProps = getFluidGatsbyImage(
          node.asset._id,
          { maxWidth: 1024 },
          clientConfig.sanity
        )
        const v = new Vibrant(fluidProps.src)

        return await v.getPalette()
      })
      const palletes = await Promise.all(pallette).then((data) => data)
      setImagesPalette(palletes)
    }

    getPalletes(images)

    return () => document.documentElement.style.setProperty("--bodyTextColor", null)
  }, [images])

  useEffect(() => handleSlide(null, 0), [imagesPalette])

  function handleSlide(_slide: number, next: number) {
    if (imagesPalette) {
      const bodyTextColor = imagesPalette[next].Vibrant.getBodyTextColor()

      if (typeof window !== "undefined") {
        document.documentElement.style.setProperty("--bodyTextColor", bodyTextColor)
      }
    }
  }

  return (
    <Carousel
      autoplay
      transitionMode="fade"
      pauseOnHover={false}
      speed={1000}
      autoplayInterval={5000}
      withoutControls
      wrapAround
      dragging={false}
      swiping={false}
      opacityScale={0.9}
      easing={"easeLinear"}
      initialSlideHeight={2000}
      beforeSlide={handleSlide}
    >
      {images.map((node, index) => {
        if (!node) return null
        const fluidProps = getFluidGatsbyImage(
          node.asset._id,
          { maxWidth: 1024 },
          clientConfig.sanity
        )

        const bodyTextColor = imagesPalette ? imagesPalette[index].Vibrant.getBodyTextColor() : null

        if (typeof window !== "undefined") {
          document.documentElement.style.setProperty(
            "--bodyTextColor",
            bodyTextColor === "#fff" ? "#000" : "#fff"
          )
        }
        const gradientColor = bodyTextColor === "#fff" ? "rgba(0,0,0,0.4)" : "rgba(255,255,255,0.4)"

        return (
          <div
            key={node.asset._id}
            className="relative"
            style={{
              height: "100vh",
            }}
          >
            <div
              className="absolute z-50 inset-0 bg-white"
              style={{
                background: `linear-gradient(0deg, ${gradientColor} 0%, transparent 20%, transparent 80%, ${gradientColor} 100%)`,
              }}
            ></div>
            <span
              className="block fixed xs:text-xs"
              style={{
                bottom: "1.5rem",
                left: "1.5rem",
                fontWeight: 400,
                zIndex: 999,
                color: imagesPalette ? imagesPalette[index].Vibrant.getBodyTextColor() : "#000",
              }}
            >
              {node.caption}
            </span>
            <Image fluid={fluidProps} style={{ height: "100vh" }} alt={node.alt} />
          </div>
        )
      })}
    </Carousel>
  )
}

export default ImageCarousel
