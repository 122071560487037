import React from "react";
import { graphql } from "gatsby";
import Errors from "../components/errors";
import FrontPage from "../templates/frontpage";

export const query = graphql`
  query FrontpageQuery {
    page: sanityFrontpage {
      title
      navMenu {
        ...NavMenu
      }
      projects {
        id
        title
        featureImage {
          ...SanityImage
          alt
        }
        mainImage {
          ...SanityImage
          alt
        }
      }
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      primaryColor {
        hex
      }
      secondaryColor {
        hex
      }
      title
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return <Errors errors={errors} />;
  }

  return <FrontPage data={data} />;
};

export default IndexPage;
