import React from "react"
import SEO from "../components/seo"
import Layout from "../containers/layout"

import ImageCarousel from "../components/ImageCarousel"

const FrontPage = ({ data }) => {
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const page = data.page || {}

  const menuItems = page.navMenu && (page.navMenu.items || [])

  const images = page.projects.map((project) => project.featureImage)

  return (
    <Layout navMenuItems={menuItems} textWhite={true}>
      <SEO
        title={site.title}
        description={site.openGraph?.description}
        image={site.openGraph?.image}
        keywords={site.keywords}
        bodyAttr={{
          class: "leading-normal tracking-normal",
        }}
      />
      <FillWindow>
        <ImageCarousel images={images} />
      </FillWindow>
    </Layout>
  )
}

// const PositionAbsolute = (props) => (
//   <div style={{ position: "relative" }}>
//     <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
//       {props.children}
//     </div>
//   </div>
// );

// const FillScreen = (props) => (
//   <div style={{ width: "100vw", height: "100vh" }}>
//     <PositionAbsolute>{props.children}</PositionAbsolute>
//   </div>
// );

const FillWindow = (props) => (
  <div className="inset-0 absolute overflow-hidden">{props.children}</div>
)

export default FrontPage
